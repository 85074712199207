import Link from "next/link";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { AppState } from "@app/store/store";
import { RouterConfig } from "@shared/lib/routerConfig";
import { ProductMyOfferProps } from "./types";
import classes from "./product-my-offer.module.scss";

export const ProductMyOffer = ({ offer }: ProductMyOfferProps) => {
    const currentUser = useSelector((state: AppState) => state.auth.currentUser);
    const publishDate = DateTime.fromISO(offer.createdAt)
        .setLocale("ru")
        .toFormat("d MMMM 'в' HH:mm");

    const isMyOffer = offer.sourceId !== 0 || currentUser?.phone !== offer.phone;

    return isMyOffer ? null : (
        <div className={classes.MyOffer}>
            <div className={classes.MyOffer__title}>Управление объявлением</div>
            <div className={classes.MyOffer__props}>
                <span className={classes.MyOffer__label}>Дата размещения: </span>
                {publishDate}
            </div>
            <div className={classes.MyOffer__actions}>
                <Link
                    href={`${RouterConfig.MY_ADS_EDIT}/${offer.id}`}
                    className={classes.MyOffer__edit}
                >
                    Редактировать
                </Link>
            </div>
        </div>
    );
};