import SvgInterface from "@shared/lib/interfaces/SvgInterface";
import React from "react";

export default React.memo(function ArrowRightIcon({ color = 'white', ...other }: SvgInterface) {
    return (
        <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.4343 12.5657C17.7467 12.2533 18.2533 12.2533 18.5657 12.5657L23.0912 17.0912C23.4036 17.4036 23.4036 17.9101 23.0912 18.2225L18.5657 22.748C18.2533 23.0604 17.7467 23.0604 17.4343 22.748C17.1219 22.4356 17.1219 21.9291 17.4343 21.6167L21.3941 17.6569L17.4343 13.6971C17.1219 13.3846 17.1219 12.8781 17.4343 12.5657Z"
                fill={color}
            />
        </svg>
    );
});
