import React, { useEffect, useState } from "react";
import Link from "next/link";
import { useDispatch } from "react-redux";
import { TwoColumnsProductCardBP } from "@app/styles/layout-vars";
import { useGetBannersQuery, useGetRecommendedOffersQuery } from "@app/store/offersApi";
import { showEnterForm } from "@app/store/layoutSlice";
import ProductChartLine from "@features/charts/ProductChartLine/ProductChartLine";
import ProductContactCard from "@features/product/product-contact-card/product-contact-card";
import { ProductCard } from "@features/product";
import { ProductMyOffer } from "@features/product/product-my-offer";
import { Banner } from "@components/Features/Catalog/CatalogList/types";
import { BannerImage } from "@components/Features/Catalog/Banner";
import SimilarProductsList from "@components/Features/SimilarProductsList/SimilarProductsList";
import { useWindowSize } from "@src/hooks/useWindowsSize";
import Car from "@shared/lib/interfaces/car.interface";
import { RouterConfig } from "@shared/lib/routerConfig";
import { AdBlock } from "@shared/ui/AdBlock";
import { AD_BLOCK_4_ID } from "@shared/ui/AdBlock/constants";
import { Breadcrumbs } from "@shared/ui/Breadcrumbs";
import { useScroll } from "@shared/lib/hooks/useScroll";
import { updateCounterViews } from "./lib/offerViewCounter";
import { ProductScreenProps } from "./types";
import {
    ERROR_RECOMMENDED_TEXT,
    productErrorTexts,
    SCROLL_TRIGGER,
    SHOW_ENTER_FORM_MESSAGE
} from "./constants";
import classes from "./product-screen.module.scss";
import dynamic from "next/dynamic";

const MotionDiv = dynamic(() => import("framer-motion").then((mod) => mod.motion.div), {
    ssr: false
});

const ProductScreen = ({ preloadedData, sellerData }: ProductScreenProps) => {
    const preloadError = !preloadedData || !preloadedData.id;
    const hasError = !preloadError && preloadedData;

    const dispatch = useDispatch();

    const {
        data: recommendedOffers,
        error: recommendedOffersError,
        isFetching: recommendedOffersIsFetching
    } = useGetRecommendedOffersQuery(preloadedData?.id, {
        skip: !preloadedData?.id
    });
    const { data: dataBanners } = useGetBannersQuery({});

    const [recommendedData, setRecommendedData] = useState<Car[]>([]);
    const [banners, setBanners] = useState<Banner>();

    const size = useWindowSize();
    const [isSticked, setIsSticked] = useState(true);
    const variants = {
        sticked: { y: [0, 5, 0] },
        standart: { y: 0 }
    };

    const [isOpen, setIsOpen] = useState(false);
    const { scrollY } = useScroll();
    scrollY.onChange((y) => setIsOpen(y > SCROLL_TRIGGER && !isOpen));

    useEffect(() => {
        if (recommendedOffers) {
            setRecommendedData(recommendedOffers?.offers);
            setBanners(recommendedOffers?.banners?.[0]);
        }
    }, [recommendedOffers, recommendedOffersIsFetching]);

    useEffect(() => {
        if (size.width) {
            if (size.width < TwoColumnsProductCardBP) {
                setIsSticked(false);
            } else {
                setIsSticked(true);
            }
        }
    });

    useEffect(() => {
        if (typeof window !== "undefined") {
            const views = updateCounterViews();
            if (views === 10) {
                dispatch(showEnterForm(SHOW_ENTER_FORM_MESSAGE));
            }
        }
    }, []);

    return (
        <div className={classes.ProductScreen}>
            <Breadcrumbs
                prevItem={{ href: RouterConfig.CATALOG, text: "Каталог" }}
                title={preloadedData?.title || ""}
            />
            {hasError ? (
                <>
                    <div className={classes.ProductScreen__chart}>
                        <ProductChartLine offer={preloadedData} />
                    </div>
                    <div className={classes.ProductScreen__inner}>
                        <div className={classes.ProductScreen__column}>
                            <ProductCard offer={preloadedData} />
                        </div>

                        <MotionDiv
                            className={classes.ProductScreen__contact}
                            animate={isOpen && isSticked ? "sticked" : "standart"}
                            variants={variants}
                            transition={{
                                type: "spring",
                                damping: 5,
                                stiffness: 50,
                                restDelta: 0.001,
                                duration: 0.34
                            }}
                        >
                            <ProductMyOffer offer={preloadedData} />
                            <ProductContactCard offer={preloadedData} sellerData={sellerData} />
                            {dataBanners?.data?.length > 0 && (
                                <div className={classes.Banner}>
                                    <BannerImage
                                        banners={dataBanners?.data?.[0] as Banner}
                                        card={true}
                                        isProductScreen={true}
                                    />
                                </div>
                            )}
                            <AdBlock blockId={AD_BLOCK_4_ID} />
                        </MotionDiv>
                    </div>

                    <SimilarProductsList
                        data={recommendedData}
                        isLoading={recommendedOffersIsFetching}
                        banners={banners}
                    />
                    {!!recommendedOffersError && <div>{ERROR_RECOMMENDED_TEXT}</div>}
                </>
            ) : (
                <div className={classes.ProductScreen__error}>
                    <h1 className={classes["ProductScreen__error--title"]}>
                        {productErrorTexts.TITLE}
                    </h1>
                    <p className={classes["ProductScreen__error--text"]}>
                        {productErrorTexts.DESCRIPTION}
                    </p>
                    <p className={classes["ProductScreen__error--text"]}>
                        {productErrorTexts.DESCRIPTION_ACTION}
                    </p>
                    <p className={classes["ProductScreen__error--text"]}>
                        {productErrorTexts.ACTION}{" "}
                        <Link
                            className={classes["ProductScreen__error--link"]}
                            href={RouterConfig.MAIN}
                        >
                            главную страницу
                        </Link>{" "}
                        или на{" "}
                        <Link
                            className={classes["ProductScreen__error--link"]}
                            href={RouterConfig.CATALOG}
                        >
                            страницу объявлений
                        </Link>
                        .
                    </p>
                </div>
            )}
        </div>
    );
};
export default ProductScreen;