import { DromIcon } from "./DromIcon";
import { AvitoIcon } from "./AvitoIcon";
import { AutoRuIcon } from "./AutoRuIcon";
import { AsterKzIcon } from "./AsterKzIcon";
import { MyCarIcon } from "./MyCarIcon";
import { KolesaKzIcon } from "./KolesaKzIcon";
import { MobileDeIcon } from "./MobileDeIcon";
import { AvByIcon } from "./AvByIcon";
import { LikviIcon } from "./LikviIcon";
import { IconsSprite } from "@shared/ui/Icon/ui/types";
import { MigTorgBlackIcon } from "./MigTorgBlackIcon";
import { UremontIconBlack } from "./UremontIconBlack";

export const BoardsSprite: IconsSprite = {
    0: LikviIcon,
    1: DromIcon,
    2: AvitoIcon,
    3: AutoRuIcon,
    6: AsterKzIcon,
    7: MyCarIcon,
    8: KolesaKzIcon,
    9: MobileDeIcon,
    10: AvByIcon,
    11: UremontIconBlack,
    12: MigTorgBlackIcon
};