import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AppState } from "@app/store/store";
import { getAddress } from "../../../utils/utils";
import { LocationIcon } from "@shared/ui/Icon/ui/Common/LocationIcon";
import { YandexMapLoader } from "@shared/ui/YandexMapLoader";
import { ProductLocationProps } from "./types";
import { LOCATION_TITLE_TEXT } from "./constants";
import classes from "./product-location.module.scss";

const ProductLocation = ({ offer }: ProductLocationProps) => {
    const [isMapVisible, setIsMapVisible] = useState(false);

    const mapLoaded = useSelector((state: AppState) => state.layout.mapLoaded);
    const addresText = getAddress(offer);

    const clearMapContainer = () => {
        const mapContainer = document.getElementById("YMapsID");
        if (mapContainer) {
            mapContainer.innerHTML = "";
        }
    };

    const handleScrollMap = () => {
        const mapElement = document.getElementById("YMapsID");
        if (mapElement) {
            const rect = mapElement.getBoundingClientRect();
            if (rect.top <= window.innerHeight && rect.bottom >= 0) {
                setIsMapVisible(true);
            }
        }
    };

    const initMap = () => {
        const ymaps = window.ymaps;
        if (!ymaps?.ready) {
            return;
        }
        ymaps.ready(function () {
            let addresLines = [];
            if (offer.city) {
                addresLines.push(offer.city);
            }
            if (offer.address) {
                addresLines.push(offer.address);
            }

            const hasCoords = offer.latitude && offer.longitude;

            if (hasCoords) {
                clearMapContainer();

                const coords = [offer.latitude, offer.longitude];
                const myMap = new ymaps.Map("YMapsID", {
                    center: coords,
                    zoom: 11
                });

                const myPlacemark = new ymaps.Placemark(coords, {
                    balloonContentHeader: offer.title,
                    balloonContentBody: offer.title,
                    balloonContentFooter: offer.title,
                    hintContent: offer.title
                });
                myMap.behaviors.disable("scrollZoom");
                myMap.geoObjects.add(myPlacemark);
            } else {
                ymaps
                    .geocode(addresLines.join(", "))
                    .then(function (res) {
                        clearMapContainer();

                        const firstGeoObject = res.geoObjects.get(0);
                        const finalCoords =
                            firstGeoObject && firstGeoObject.geometry
                                ? firstGeoObject.geometry.getCoordinates()
                                : [55.699733, 37.987425];

                        const myMap = new ymaps.Map("YMapsID", {
                            center: finalCoords,
                            zoom: 11
                        });

                        const myPlacemark = new ymaps.Placemark(finalCoords, {
                            balloonContentHeader: offer.title,
                            balloonContentBody: offer.title,
                            balloonContentFooter: offer.title,
                            hintContent: offer.title
                        });
                        myMap.behaviors.disable("scrollZoom");
                        myMap.geoObjects.add(myPlacemark);
                    })
                    .catch(function () {
                        clearMapContainer();

                        const myMap = new ymaps.Map("YMapsID", {
                            center: [55.7522, 37.6156],
                            zoom: 11
                        });

                        const myPlacemark = new ymaps.Placemark([55.7522, 37.6156], {
                            balloonContentHeader: offer.title,
                            balloonContentBody: offer.title,
                            balloonContentFooter: offer.title,
                            hintContent: offer.title
                        });
                        myMap.behaviors.disable("scrollZoom");
                        myMap.geoObjects.add(myPlacemark);
                    });
            }
        });
    };

    useEffect(() => {
        initMap();
    }, [mapLoaded]);

    useEffect(() => {
        window.addEventListener("scroll", handleScrollMap);
        return () => {
            window.removeEventListener("scroll", handleScrollMap);
        };
    }, []);

    return (
        <div className={classes["product-location"]}>
            <h3 className={classes["product-location__title"]}>{LOCATION_TITLE_TEXT}</h3>
            <div className={classes["product-location__name"]}>
                <LocationIcon />
                {addresText}
            </div>
            <div id="YMapsID" className={classes["product-location__map"]}></div>
            {isMapVisible && <YandexMapLoader />}
        </div>
    );
};
export default ProductLocation;