import React, { FC, useRef, useState } from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";

import { ShareIcon } from "@shared/ui/Icon/ui/Socials/ShareIcon";
import { VkIcon } from "@shared/ui/Icon/ui/Socials/VkIcon";
import { TelegramIcon } from "@shared/ui/Icon/ui/Socials/TelegramIcon";
import { CopyLinkIcon } from "@shared/ui/Icon/ui/Socials/CopyLinkIcon";
import { useEscapeKey } from "@shared/lib/hooks/useEscapeKey";
import { useOutsideClick } from "@shared/lib/hooks/useOutsideClick";
import { useCopyToClipboard } from "@shared/lib/hooks/useCopyToClipboard";
import { ARIA_LABEL_TEXT, TELEGRAM_SHARE_URL, VK_SHARE_URL } from "./constants";

import classes from "./product-share.module.scss";

export const ProductShare: FC = () => {
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const productShareRef = useRef<HTMLDivElement>(null);

    const pathname = usePathname();
    const productLink = `${process.env.NEXT_PUBLIC_DOMAIN}${pathname}`;
    const [copiedUrl, copyUrlToClipboard] = useCopyToClipboard(productLink);

    const toggleTooltip = () => {
        setIsTooltipVisible((prev) => !prev);
    };

    useOutsideClick(productShareRef, () => {
        setIsTooltipVisible(false);
    });

    useEscapeKey(() => {
        setIsTooltipVisible(false);
    });

    return (
        <div className={classes.productShare} ref={productShareRef}>
            <button
                className={classes.productShare__button}
                onClick={toggleTooltip}
                aria-label={ARIA_LABEL_TEXT}
            >
                <ShareIcon />
            </button>
            {isTooltipVisible && (
                <div className={classes.productShare__popover}>
                    <Link
                        className={classes["productShare__popover-item"]}
                        href={`${VK_SHARE_URL}${productLink}`}
                        rel="nofollow noreferrer"
                        target="_blank"
                    >
                        <VkIcon />
                    </Link>
                    <Link
                        className={classes["productShare__popover-item"]}
                        href={`${TELEGRAM_SHARE_URL}${productLink}`}
                        rel="nofollow noreferrer"
                        target="_blank"
                    >
                        <TelegramIcon />
                    </Link>
                    <div
                        className={`${classes["productShare__popover-item"]} ${copiedUrl ? classes["productShare__copied"] : ""}`}
                        onClick={() => copyUrlToClipboard()}
                    >
                        <CopyLinkIcon />
                    </div>
                </div>
            )}
        </div>
    );
};